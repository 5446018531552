html {
  height: 100vh;
  box-sizing: border-box;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

body {
  margin: 0px;
  font-family: Roboto,Arial,sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #404040;
}

#root {
  min-height: 100vh;
  max-width: 100vw;
  background-color: #f1f1f4;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr 80px;
  grid-template-areas:
  "header"
  "greeting"
  "main"
  "footer";
}

h1 {
  font-weight: 500;
  line-height: 1;
  color: #212F3D;
}

h2 {
  font-weight: 500;
  margin: 12px 0px;
  color: #212F3D;
}

a {
  text-decoration: none;
  color: #2C3E50;
  cursor: pointer;
}

label {
  margin-top: 10px;
  font-size: .75rem;
  font-weight: bold;
}

input, select {
  font-size: 14px;
  background-color: #f8f8fa;
  padding: 7px 5px;
  border-radius: 0px;
  border-style: solid;
  border-color: #a4a4a4;
  border-width: 1px;
}

form {
  display: flex;
  flex-direction: column;
  width: max-content;
  max-width: 100%;
}

button {
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #00af4f; 
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  min-width: 100px;
  max-width: fit-content;
  border-width: 1px;
}

.material-icons {
  font-size: 48px;
  color: #212F3D;
}

.default-body {
  grid-area: main;
  padding: 12px;
}

.loading {
  width: 128px;
  height: 128px;
}

.paper {
  background-color: #fff;
  box-shadow: 1px 2px 1px -1px rgba(0,0,0,0.6),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  margin: 0px 0px 5px 0px;
  overflow: hidden;
}


/* ordering important */

.md, .lg {
  display: none;
}

.sm {
  display: block;
}
/*--------------------*/

@media (min-width: 500px) {

/* ordering important */

  .sm {
    display: none;
  }

  .md {
    display: block;
  }
/*--------------------*/
}

@media only screen and (min-width: 900px) {

  #root {
    min-height: 951px;
    display: grid;
    grid-template-columns: 17.5% 65% 17.5%;
    grid-template-rows: auto auto 1fr 80px;
    grid-template-areas:
    "header header header"
    "leftGutter greeting rightGutter"
    "leftGutter main rightGutter"
    "footer footer footer";
  }

  .default-body {
    padding: 10px 0px;
  }

  .paper {
    margin: 5px;
  }

/* ordering important */
  .md {
    display: none;
  }

  .lg {
    display: block;
  }

  th.lg, td.lg {
    display: table-cell;
  }

/*--------------------*/

}

