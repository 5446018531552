header {
  grid-area: header;
  background-color: #212F3D;
  padding: 0px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(142, 157, 168, 0.5);
  box-shadow: 2px 2px 5px grey;
}


nav {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}


.nav-links {
  max-height: 0px;
  transition: max-height 0.25s ease-out;
  cursor: pointer;
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.toggle-nav-links {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

.nav-links li {
  width: 100%;
  list-style: none;
}

.nav-links li a {
  text-transform: uppercase;
  font-size: .875rem;
  font-weight: 500;
  width: 100%;
  outline: none;
  text-decoration: none;
  color: inherit;
  display: inline-block;
  padding: 15px;
  border-top: 1px solid;
  border-color: #364D63;
}

.nav-links li a:hover {
  color: #bdcbdb;
}

.logo-and-menu-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  outline: none;
  text-decoration: none;
  padding: 15px;
  line-height: .8;
}

.menu-button {
  outline: none;
  text-decoration: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 36px;
  text-align: center;
  background-color: #212F3D;
  color: white;
}

.greeting {
  grid-area: greeting;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.greeting p {
  font-size: 14px;
  margin: 3px 10px;
}

.logout {
  cursor: pointer;
  color: #0f576a;
  padding-left: 5px;
  display: inline;
}

footer {
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  #1E3042;
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  color: white;
}


@media (min-width: 500px) {

  nav {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .logo-and-menu-button {
    width: auto;
  }

  .logo {
    padding: 15px 0px;
  }
  .nav-links {
    max-height: unset;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 66%;
  }

  .nav-links li {
    width: auto;
  }
  
  .nav-links li a {
    border-top: none;
    width: inherit;
  }
}


@media (min-width: 900px) {

  header {
    padding: 0 17.5%;
  }

  .logo {
    padding: 15px 0;
  }

  .nav-links {
    width: 50%;
  }
  
}