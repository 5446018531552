.register {
  padding-left: 5px;
  text-decoration: none;
}

.warning {
  color: darkred;
  font-size: 14px;
}

.login, .register {
  padding: 10px;
  max-width: 100%;
  width: max-content;
}

.login-form {
    padding: 15px;
    width: min-content;
    height: min-content;
}