.toc {
  width: 200px;
  list-style: none;
  padding-left: 0px;
}

.toc li {
  padding: 10px 0px;
  border-bottom: 1px solid;
}

.toc li a {
  padding: 10px 100px 10px 10px;
  color: #2C3E50;
}

.table-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.excel-icon {
  align-self: end;
  cursor: pointer;
  padding: 0px;
  border: none;
  height: 64px;
  width: 64px;
  background-image: url('../img/calc.jpg');
}

table {
  padding-top: 15px;
  width: 100%;
}

td, th {
  border: 1px solid rgba(58, 68, 82, 0.35);
  padding: 8px;
  border-radius: 2px;
  overflow: hidden;
}

th {
  text-align: left;
  background-color: #212F3DE6;
  color: white;
}

tr:nth-child(even) {
  background-color: #fff;
}

td, th {
  font-size: 14px;
}
