.book-unit {
    box-sizing: border-box;
    min-height: 300px;
    max-width: 500px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.choice {
    display: flex;
    flex-direction: row;
}

.choice-stacked {
    display: flex;
    flex-direction: column;
    width: 150px;
}

.navigator {
    max-width: 500px;
    display: flex;
    padding: 5px;
    justify-content: space-between;
}

.back-button {
    background-color: #f1f1f4;
    color: #00af4f;
    border-style: solid;
    border-width: 1px;
    border-color: #00af4f;
}
