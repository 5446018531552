.home {
  padding-bottom: 10px;
  display: grid;
  grid-area: main;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
  "picture"
  "headline"
  "units"
  "map";
}

.picture-div {
  grid-area: picture;
  height: 270px;
}

.headline {
  grid-area: headline;
  margin-left: 15px;
}

.units {
  grid-area: units;
  display: grid;
  grid-template-rows: 50% 50%;
  max-width: 100vw;
}

.unit-div {
  display: flex;
  height: 372px;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  max-width: 100%;
  border-bottom: 1px solid gray;
}

.unit-features-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: .5;
  font-size: .9rem;
  font-weight: 400;
  margin: 15px;
}

.unit-features-div p {
  margin: 5px;
}

.per-month {
  font-size: smaller;
}

.strike-through-price {
  text-decoration: line-through;
  color: #999999; 
}

.book-now {
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #00af4f; 
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  min-width: 100px;
  max-width: fit-content;
  border: none;
}

.map {
  grid-area: map;
  text-align: center;
}

#google-map {
  height: 362px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(58, 68, 82, 0.35);
}

.phone {
  text-decoration: underline;
  font-weight: normal;
  color: #0f576a;
}

@media screen and (min-width: 500px) {

  .picture-div {
    height: 482px;
    overflow: hidden;
    grid-area: picture;
  }

  .picture {
    height: 482px;
    width: auto;
  }

  .units {
    min-width: 475px;
    max-width: 100vw;
    height: 364px;
  }

  .unit-div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    height: 181px;
  }

  .unit-features-div {
    margin-left: 20px;
  }

}

@media screen and (min-width: 900px) {

  .home {
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
    "picture picture"
    "headline headline"
    "units map";
  }
}