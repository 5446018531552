.billing {
    padding: 10px;
    width: 380px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.balance-amount {
  font-size: 2rem;
}

.horizontal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 225px;
}

.stripe {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100px;
    width: 335px;
}

.stripe-input {
  background-color: #f8f8fa;
  padding: 7px 5px;
  border-style: solid;
  border-color: #a4a4a4;
  border-width: 1px;
}

.anchor {
  margin-top: 15px;
  cursor: pointer;
}

.material-icons-payment {
  padding-top: 3px;
  font-size: 20px;
}

.material-icons-storefront {
  padding-top: 3px;
  font-size: 20px;
}

.radio-option {
  height: 25px;
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.radio-option-label {
  margin-left: 5px;
  padding-bottom: 5px;
  font-weight: normal;
  font-size: .9rem;
  display: inline-block;
  width: 75px;
  white-space: nowrap;
}